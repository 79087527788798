<template>
  <div class="leet-index-page">
    <div class="page-header">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in list" :key="index">
          <img :src="item.src" alt="" srcset="" />
          <!-- <div class="title-box" v-if="index === 1">
            <div class="title-content">
              <div class="top-title animate__animated animate__backInLeft">
                简捷物联
              </div>
              <div
                class="info-des animate__animated animate__backInLeft animate__delay-1s"
              >
                将物联网技术与数智控碳相融合<br />
                提供 <span>全生态零碳数智化解决方案</span>
              </div>
            </div>
          </div> -->
          <div class="title-sub-box" v-if="index === 1">
            <div
              class="title-sub-content animate__animated animate__backInLeft"
            >
              <div>让储能集成更“简”单<br />让能量管理更便“捷”</div>
            </div>
          </div>
          <div class="title-sub-box" v-if="index === 0">
            <!-- <div
              class="title-sub-content animate__animated animate__backInLeft"
            >
              <div>让物“联”更简单<br />让数“聚”更便捷</div>
            </div> -->
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="page-body">
      <div class="page-body-content">
        <div class="selection-part-one">
          <titleBar title="关于简捷"></titleBar>
          <div class="content">
            <p>
              浙江简捷物联科技有限公司依托核心专利“物联网边缘操作系统”技术，以不断创新的储能EMS产品为基础，提供从设计到运维的端到端全链条服务支撑，实现用户侧能源管理的全场景覆盖，为客户打造专业高效、经济省心、开放赋能的数智化能源管理解决方案，从而助力客户构筑自有产品的差异化竞争壁垒，共创生态多赢新格局。
            </p>
            <p>
              简捷物联坚持持续自主创新，始终致力于引领能源数智化新潮流，并与客户和行业伙伴携手共同打造储能行业高质量发展的新格局。
            </p>
          </div>
        </div>
        <div class="introduction">
          <div class="introduction-item">
            <div class="introduction-item-num">40<span class="plus">+</span></div>
            <div class="introduction-item-title">发明专利及软著</div>
          </div>
          <div class="introduction-item">
            <div class="introduction-item-num">70<span class="percentSigns">%</span></div>
            <div class="introduction-item-title">研发人员占比</div>
          </div>
          <div class="introduction-item">
            <div class="introduction-item-num">10<span class="plus">+</span></div>
            <div class="introduction-item-title">公司资质</div>
            <div class="introduction-item-content">
              <p>国家高新技术企业</p>
              <p>浙江省专精特新中小企业</p>
            </div>
          </div>
          <div class="introduction-item">
            <div class="introduction-item-num">20<span class="plus">+</span></div>
            <div class="introduction-item-title">行业荣誉奖项</div>
            <div class="introduction-item-content">
              <p>最佳储能EMS供应商奖</p>
              <p>长三角百家品牌软件企业</p>
            </div>
          </div>
        </div>
        <!-- <div class="selection-part-two">
          <titleBar title="服务客户"></titleBar>
          <div class="content">
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-wrapper">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-wrapper">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-item">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
            <div class="band-wrapper">
              <img src="/img/band.png" alt="" srcset="" />
            </div>
          </div>
        </div> -->
      </div>
      <div class="page-body-brandValue">
        <div class="page-body-brandValue-box">
          <titleBar title="品牌价值"></titleBar>
          <div class="brandValue-top">
            <div>独有核心专利技术</div>
            <div style="margin-top: 10px">坚持创新，拒绝拼凑</div>
          </div>
          <div class="brandValue-bottom">
            <div class="brandValue-bottom-item">硬核</div>
            <div class="brandValue-bottom-item">周全</div>
            <div class="brandValue-bottom-item">共赢</div>

            <div class="brandValue-bottom-left">
              <p>不止EMS，<br />储能端到端全链条服务支撑</p>
              <p>不止储能，<br />用户侧能源管理全场景覆盖</p>
            </div>
            <div class="brandValue-bottom-right">
              <p>助力客户<br />构筑自有产品竞争壁垒</p>
              <p>共创生态<br />多赢全新格局</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        class="page-full-body-content"
        style="
          background-image: url('https://cdn.lnxall.com/index-bg.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        "
      >
        <div class="selection-part-three">
          <titleBar title="公司资质"></titleBar>
          <div class="info-content">
            <div>简捷物联是国家高新技术企业</div>
            <div>浙江省专精特新企业，</div>
            <div>拥有二十余项发明专利和软件著作权</div>
          </div>
          <div class="wall-content">
            <div
              class="leftcon"
              style="
                background: url('https://cdn.lnxall.com/indexcard.png') no-repeat;
                background-size: 100% 100%;
              "
            ></div>
            <div class="rightcon">
              <div
                class="item-list"
                style="background: url('https://cdn.lnxall.com/mobile/card01.png') no-repeat;background-size: 100% 100%;"
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card02.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card03.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card04.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card05.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card06.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card07.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
              <div
                class="item-list"
                style="
                  background: url('https://cdn.lnxall.com/mobile/card08.png') no-repeat;background-size: 100% 100%;
                "
              ></div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
import titleBar from "../../components/titleBar";
export default {
  name: "index-page",
  components: {
    titleBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      list: [
        {
          src: "https://cdn.lnxall.com/img/Banner1.png",
        },
        {
          src: "https://cdn.lnxall.com/img/Banner2.png",
        },
      ],
    };
  },
  mounted() {},
  filters: {},
  created() {},
  methods: {},
  destroyed() {},
};
</script>
<style scoped lang="less">
.leet-index-page {
  .page-header {
    background-color: #070508;
    .my-swipe {
      width: 100%;
      height: 260px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title-box {
      position: absolute;
      left: 20px;
      right: 0;
      top: 60px;
      box-sizing: border-box;
      z-index: 99;
      .title-content {
        margin: 0 auto;
        .top-title {
          height: 70px;
          color: #ffffff;
          font-size: 24px;
          font-family: PingFangSC-Medium;
          line-height: 70px;
        }
        .info-des {
          margin-top: 10px;
          font-size: 22px;
          font-family: PingFangSC-Light;
          color: #ffffff;
          line-height: 28px;
          span {
            font-size: 24px;
            color: #ffffff;
            font-family: PingFangSC-Medium;
          }
        }
      }
    }
    .title-sub-box {
      position: absolute;
      top: 90px;
      left: 20px;
      right: 20px;
      .title-sub-content {
        height: 66px;
        color: #ffffff;
        font-size: 22px;
        letter-spacing: 1px;
        font-family: PingFangSC-Semibold;
        line-height: 66px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .page-body {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    overflow: hidden;
    .page-body-content {
      padding: 15px;
      box-sizing: border-box;
      background: #F6FAFF;
      .selection-part-one {
        padding: 20px 0 0 0;
        box-sizing: border-box;
        .content {
          p {
            overflow-wrap: break-word;
            color: #222222;
            font-size: 14px;
            line-height: 28px;
            text-align: left;
            margin-top: 10px;
          }
        }
      }
      .introduction {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;

        &-item{
          width: 45%;
          text-align: center;
          box-sizing: border-box;
          position: relative;
          
          &-num {
            font-weight: 500;
            font-size: 44px;
            color: #004CA2;
            line-height: 44px;
            letter-spacing: 4px;
            height: 44px;

            .plus {
              font-size: 35px;
              vertical-align: 5px;
            }
            .percentSigns {
              font-size: 22.5px;
              font-weight: bold;
            }
          }
          &-title {
            margin-top: 10px;
            font-weight: 600;
            font-size: 14px;
            color: #555555;
            line-height: 15px;
            letter-spacing: 1px;
          }
          &-content {
            p {
              font-weight: 400;
              font-size: 12px;
              color: #222222;
              text-align: center;
            }
            p:last-child {
             margin-bottom: 0; 
            }
          }
        }
      }
      .selection-part-two {
        // height: 560px;
        box-sizing: border-box;
        .content {
          margin-top: 60px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          box-sizing: border-box;
          .band-item {
            height: 108px;
            border-radius: 4px;
            background-color: white;
            box-shadow: 0px 16px 32px 0px rgb(199 205 226 / 20%);
            width: 270px;
            margin: 0 40px 30px 0;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
          }
          .band-wrapper {
            height: 108px;
            border-radius: 4px;
            background-color: white;
            box-shadow: 0px 16px 32px 0px rgb(199 205 226 / 20%);
            width: 270px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .page-full-body-content {
      width: 100%;
      .selection-part-three {
        box-sizing: border-box;
        padding: 15px;
        text-align: center;
        .info-content {
          margin: 20px 0 20px 0;
          display: inline-block;
          overflow-wrap: break-word;
          color: #222222;
          font-size: 14px;
          line-height: 28px;
        }
        .wall-content {
          border-radius: 8px;
          box-shadow: 0px 16px 24px 0px rgb(183 188 205 / 20%);
          width: 100%;
          background-color: #fff;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          padding: 10px 0;

          .leftcon {
            box-sizing: border-box;
            width: 100px;
            height: 142px;
            box-shadow: 0px 7px 14px 0px rgb(161 163 173 / 22%);
          }
          .rightcon {
            width: calc(100% - 100px);
            flex-wrap: wrap;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            box-sizing: border-box;
            .item-list {
              width: 58px;
              height: 80px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
    .page-body-brandValue {
      width: 100%;
      box-sizing: border-box;
      background: url("https://cdn.lnxall.com/img/BrandValueBG.png") no-repeat;
      background-size: 100% 100%;
      &-box {
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .brandValue-top {
          font-size: 16px;
          color: #222222;
          line-height: 20px;
          text-align: center;
          margin: 16px 0 10px 0;
          display: inline-block;
          overflow-wrap: break-word;
          letter-spacing: 5px;
        }
        .brandValue-bottom {
          width: 100%;
          height: 280px;
          background: url("https://cdn.lnxall.com/img/annulus.png") center 10% no-repeat;
          background-size: 50%;
          position: relative;
          &-item {
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 20px;
            color: #000000;
            text-align: center;
          }
          &-item:nth-child(2) {
            position: absolute;
            left: 56px;
            bottom: 98px;
          }
          &-item:nth-child(3) {
            position: absolute;
            right: 56px;
            bottom: 98px;
          }
          &-left {
            position: absolute;
            left: 10px;
            bottom: 15px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #222222;
            p {
              margin: 0 0 6px 0;
            }
          }
          &-right {
            position: absolute;
            right: 15px;
            bottom: 15px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #222222;
            p {
              margin: 0 0 6px 0;
            }
          }
        }
      }
    }
  }
  .page-body::before {
    content: '';
    position: absolute;
    top: 560px;
    left: 12px;
    width: 202px;
    height: 167px;
    background: url("https://cdn.lnxall.com/img/decorations2.png") no-repeat;
    background-size: 80% 80%;
  }
  .page-body::after {
    content: '';
    position: absolute;
    top: 325px;
    right: -40px;
    width: 178px;
    height: 191.5px;
    background: url("https://cdn.lnxall.com/img/decorations1.png") no-repeat;
    background-size: 80% 80%;
  }
}
</style>
